import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag",
      "style": {
        "position": "relative"
      }
    }}>{`Tag`}</h1>
    <p>{`The component is indicated for the cases where it's necessary to categorize, organize or name components using keywords to describe them. Tags provide a fast recognition and navigability.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Use truncate when the text size reaches 200px.`}</li>
    </ul>
    <h2 {...{
      "id": "tag-default",
      "style": {
        "position": "relative"
      }
    }}>{`Tag default`}</h2>
    <Demo src='pages/components/tag/TagDefault' mdxType="Demo" />
    <h2 {...{
      "id": "tag-with-icon",
      "style": {
        "position": "relative"
      }
    }}>{`Tag with icon`}</h2>
    <Demo src='pages/components/tag/TagIcon' mdxType="Demo" />
    <h2 {...{
      "id": "removable-tag",
      "style": {
        "position": "relative"
      }
    }}>{`Removable tag`}</h2>
    <Demo src='pages/components/tag/TagRemovable' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      